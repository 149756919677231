import type { AreaSubstrate, QuoteProduct } from 'paintscout'
import type { UpdateableProduct } from '../..'

// Converts an areaSubstrates QuoteProducts to an UpdateableProduct to go with UpdateableAreaSubstrate
// empty array if no products found on areaSubstrate
export default function convertAreaSubstrateProducts(areaSubstrate: AreaSubstrate): UpdateableProduct[] {
  const legacyProduct = legacyAreaSubstrateProduct(areaSubstrate)
  const defaultProducts = defaultAreaSubstrateProducts(areaSubstrate)
  const res = [...(legacyProduct ? [legacyProduct] : []), ...defaultProducts]

  return res
}

function legacyAreaSubstrateProduct(areaSubstrate: AreaSubstrate): UpdateableProduct {
  return areaSubstrate.product && (areaSubstrate.product.custom || (areaSubstrate?.product?.product?.name ?? false))
    ? convertProduct(areaSubstrate.product)
    : null
}

function defaultAreaSubstrateProducts(areaSubstrate: AreaSubstrate): UpdateableProduct[] {
  return areaSubstrate.products ? areaSubstrate.products.map((product) => convertProduct(product)) : []
}

function convertProduct(product: QuoteProduct): UpdateableProduct {
  return {
    key: product?.product?.name,
    label: product.product.label ?? '',
    calculate: product?.calculate ?? true,
    color: product?.product?.color ?? '',
    unitLabel: product?.product?.unitLabel ?? 'gal',
    quantity: product.volume_override ? product?.volume_override_value ?? 0 : product?.volume ?? 0,
    coverage: {
      default: product?.coverage_rate_value ?? 0,
      useCustom: product?.coverage_rate_override ?? false,
      custom: product?.coverage_rate_override_value ?? 0
    },
    lnftCoverage: {
      default: product?.lnft_coverage_rate_value ?? 0,
      useCustom: product?.lnft_coverage_rate_override ?? false,
      custom: product?.lnft_coverage_rate_override_value ?? 0
    },
    quantityCoverage: {
      default: product?.quantity_coverage_rate_value ?? 0,
      useCustom: product?.quantity_coverage_rate_override ?? false,
      custom: product?.quantity_coverage_rate_override_value ?? 0
    },
    volume: {
      default: product?.volume ?? 0,
      useCustom: product?.volume_override ?? false,
      custom: product?.volume_override_value ?? 0
    },
    price: {
      default: product?.price ?? 0,
      useCustom: product?.price_override ?? false,
      custom: product?.price_override_value ?? 0
    },
    totalPrice: {
      default: product?.totalPrice ?? 0,
      useCustom: product?.totalPrice_override ?? false,
      custom: product?.totalPrice_override_value ?? 0
    },
    quoteTypes: product?.quoteTypes ?? ['all'],
    custom: product?.custom ?? false
  }
}
