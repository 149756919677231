/**
 * @module builder
 */
import type { AreaSubstrate, OptionsDocument, QuoteArea, QuoteDocument, QuoteOptions } from 'paintscout'
import { areaTotals } from '../../../../calculator'
import type { QuoteItemSection } from '../../index'
import { getAreaSubstrateKey } from '../../area-substrates'
import getSubstrateAreas from '../../items/get-items/util/get-substrate-areas'
import { calculateQuote, generateAreaDescription, getItemSection, getQuoteOptions, moveTo } from '../../util'
import { generateSubstrateDescription } from '../../util/generate-substrate-description/generate-substrate-description'

// When Area moved go through all area.substrates sections,
// Check each areaSubstrate, if it is in the previous section, move it to the new section
// else leave it as is. (No longer track area sections)
// (Also not moving substrates, substrates will be re-generated during calculation)
export function moveArea(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  previousSection: QuoteItemSection
  calculate?: boolean
  moveSubstrates?: boolean
}): QuoteDocument {
  const { key, section, previousSection, calculate = true, options, moveSubstrates } = args
  const quote: any = { ...args.quote }

  // If area doesn't exist, return
  if (!quote.areas[key]) {
    return { ...quote }
  }

  let movedArea: QuoteArea = quote.areas[key]
  const movedAreaSubstrates: any = {}

  const areaSection = getItemSection(movedArea)
  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote
  })

  if (areaSection === previousSection) {
    movedArea = moveTo(quote.areas[key], section)
  }

  let substrateKeys = {}
  if (moveSubstrates) {
    Object.keys(movedArea.substrates).forEach((rateSection: string) => {
      movedAreaSubstrates[rateSection] = []
      movedArea.substrates[rateSection].forEach((areaSubstrate: AreaSubstrate) => {
        const areaSubstrateSection = getItemSection(areaSubstrate)

        if (areaSubstrateSection === previousSection) {
          movedAreaSubstrates[rateSection].push(moveTo(areaSubstrate, section))
          const substrateKey = getAreaSubstrateKey(quote, areaSubstrate)
          substrateKeys = {
            ...substrateKeys,
            [substrateKey]: true
          }
        } else {
          movedAreaSubstrates[rateSection].push(areaSubstrate)
        }
      })
    })
  }

  // Update area totals after areaSubstrate moves
  const updatedArea = areaTotals(
    {
      ...movedArea,
      substrates: movedAreaSubstrates
    },
    quote
  )

  // Rebuild quote areas with moved areaSubstrates
  const updatedQuote = {
    ...quote,
    areas: {
      ...quote.areas,
      [key]: {
        ...updatedArea,
        ...(moveSubstrates && { substrates: movedAreaSubstrates })
      }
    }
  }

  // Update moved areas description
  updatedQuote.areas[key].substrate_string.default = generateAreaDescription({
    quote: updatedQuote,
    options,
    areaKey: key
  })

  Object.keys(updatedQuote.substrates).forEach((substrateKey) => {
    if (updatedQuote.substrates[substrateKey]) {
      const defaultValue = generateSubstrateDescription({
        quote: updatedQuote,
        clientOptions: options,
        substrateKey: substrateKey.toString()
      })

      const areaString = updatedQuote.substrates[substrateKey].area_string
        ? { ...updatedQuote.substrates[substrateKey].area_string, default: defaultValue }
        : { use_custom: false, default: defaultValue }

      updatedQuote.substrates[substrateKey].area_string = areaString
      if (Object.keys(substrateKeys).includes(substrateKey)) {
        const substrateAreas = getSubstrateAreas({
          quote: updatedQuote,
          options: quoteOptions,
          clientOptions: options,
          substrateKey,
          consumer: 'customer',
          section: previousSection,
          showValues: true,
          showGroup: false
        })
        if (substrateAreas.length === 0) {
          updatedQuote.substrates[substrateKey] = moveTo(updatedQuote.substrates[substrateKey], section)
        }
      }
    }
  })

  // Default true for calculate, substrates rebuilt in calculateQuote
  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}
