/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection } from '../../index'
import { moveArea } from '../../areas/move-area'
import { moveSubstrate } from '../../substrates/move-substrate'
import { moveGroup } from '../../groups/move-group'
import { getEntityType, moveTo } from '../../util'

export function moveItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  previousSection: QuoteItemSection
  areaIds?: { [key: string]: string[] }
  calculate?: boolean
  force?: boolean
}): QuoteDocument {
  const { key, options, section, previousSection, calculate, force, areaIds = {} } = args
  const quote: any = { ...args.quote }

  const entityType = getEntityType({ quote, key })
  if (entityType === null) {
    return {
      ...quote
    }
  }

  if (entityType === 'areas') {
    return moveArea({ quote, options, key, section, previousSection, calculate, moveSubstrates: true })
  } else if (entityType === 'substrates') {
    return moveSubstrate({ quote, key, options, section, previousSection, calculate, force, areaIds })
  } else if (entityType === 'groups') {
    return moveGroup({ quote, key, section, previousSection, calculate: true, options })
  }

  const existing = quote[entityType]
  const movedItem = moveTo(existing[key], section)

  return {
    ...quote,
    [entityType]: {
      ...existing,
      [key]: movedItem
    }
  }
}
