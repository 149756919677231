import React, { useState } from 'react'

import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field } from 'formik'
import type { QuoteFile } from 'paintscout'
import find from 'lodash/find'
import { getFeatures, getObjectLabels } from '@paintscout/util/builder'

import {
  Grid,
  UploadImage,
  Hidden,
  FormikLayoutSelect,
  TextFormatInputField,
  CheckboxField,
  useClientOptions,
  DropdownSelect
} from '@ui/paintscout'
import type { DropdownSelectOption } from '@ui/paintscout/src/DropdownSelect'

const CoverForm = ({ formik, ..._props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: false })
  const [open, setOpen] = useState({ title: false, subtitle: false })

  const overlayImageSizes = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' }
  ]

  const useQuoteContactAddress = section?.fields?.useQuoteContactAddress ?? false
  const useFirstQuoteImage = section?.fields?.useFirstQuoteImage ?? false
  const overlayImageSize = section?.fields?.overlayImageSize ?? 'small'
  const selectedOverlayImageSize = find(overlayImageSizes, { value: overlayImageSize })

  const jobIDInCoverSection = getFeatures({ options })?.presentation?.jobIDInCoverSection

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextFormatInputField
              label={'Title'}
              fullWidth={true}
              value={section?.title}
              name={'section.title'}
              onChange={handleInputChange}
              onOpenToggle={handleToggleOpen('title')}
              open={open.title}
              styleValue={section?.fields?.titleStyle}
              onStyleChange={handleStyleChange('section.fields.titleStyle')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormatInputField
              label={'Subtitle'}
              fullWidth={true}
              multiline={true}
              value={
                useQuoteContactAddress
                  ? `This subtitle will be the ${jobIDInCoverSection ? 'job identifier and the ' : ''}job or contact address.`
                  : section?.fields?.subtitle
              }
              disabled={useQuoteContactAddress}
              name={'section.fields.subtitle'}
              onChange={handleInputChange}
              onOpenToggle={handleToggleOpen('subtitle')}
              open={open.subtitle}
              styleValue={section?.fields?.subTitleStyle}
              onStyleChange={handleStyleChange('section.fields.subTitleStyle')}
            />
          </Grid>
          <Grid item xs={12}>
            <Field component={FormikLayoutSelect} label={'Horizontal Alignment'} name="section.fields.alignment" />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={FormikLayoutSelect}
              vertical={true}
              disabled={!section?.fields?.backgroundImage && !useFirstQuoteImage}
              label="Vertical Alignment"
              name="section.fields.verticalAlign"
            />
          </Grid>
          <Hidden xsDown>
            {/* Another Located Below for smUp */}
            <Grid item xs={12}>
              <CheckboxField
                label={
                  jobIDInCoverSection
                    ? `Use job identifier and job or contact address as the subtitle.`
                    : `Use job or contact address as the subtitle.`
                }
                name="section.fields.useQuoteContactAddress"
                value={useQuoteContactAddress}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                label={`Use first image on ${objectLabels.quote.value.toLowerCase()} as background.`}
                name="section.fields.useFirstQuoteImage"
                value={useFirstQuoteImage}
                onChange={formik.handleChange}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid item container spacing={2} xs={12} sm={4}>
        <Grid item xs={12}>
          <UploadImage
            onUpload={handleUpload('section.fields.overlayImage')}
            onClear={handleClear('section.fields.overlayImage')}
            cloudinaryPublicId={section?.fields?.overlayImage?.cloudinaryPublicId}
            s3PublicKey={section?.fields?.overlayImage?.s3PublicKey}
            src={section?.fields?.overlayImage?.src}
            format={section?.fields?.overlayImage?.format}
            label={'Logo/Image'}
            maxWidth={800}
            maxHeight={800}
            buttonHeight={100}
            quality={1}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownSelect
            variant="single"
            label="Logo/Image Size"
            options={overlayImageSizes}
            value={selectedOverlayImageSize}
            fullWidth={true}
            onChange={(val: DropdownSelectOption) => {
              formik.setFieldValue('section.fields.overlayImageSize', val.value)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadImage
            onUpload={handleUpload('section.fields.backgroundImage')}
            cloudinaryPublicId={section?.fields?.backgroundImage?.cloudinaryPublicId}
            s3PublicKey={section?.fields?.backgroundImage?.s3PublicKey}
            src={section?.fields?.backgroundImage?.src}
            format={section?.fields?.backgroundImage?.format}
            disabled={useFirstQuoteImage}
            disabledNote={useFirstQuoteImage ? 'Quote Image' : null}
            onClear={handleClear('section.fields.backgroundImage')}
            label={'Background Image'}
            maxWidth={1400}
            maxHeight={1400}
            buttonHeight={100}
            quality={1}
          />
        </Grid>
      </Grid>
      <Hidden smUp>
        {/* Another Located Above for xsDown */}
        <Grid item container spacing={2} xs={12} sm={12}>
          <Grid item xs={6}>
            <CheckboxField
              label={
                jobIDInCoverSection
                  ? `Use job identifier and job or contact address as the subtitle.`
                  : `Use job or contact address as the subtitle.`
              }
              name="section.fields.useFirstQuoteImage"
              value={useFirstQuoteImage}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )

  function handleUpload(name: string) {
    return function (file: Omit<QuoteFile, 'visibility'>) {
      formik.setFieldValue(name as any, file)
    }
  }

  function handleClear(name: string) {
    return function () {
      formik.setFieldValue(name as any, null)
    }
  }

  function handleToggleOpen(name: string) {
    return (_ev: React.MouseEvent) => {
      setOpen({
        title: false,
        subtitle: false,
        [name]: !open[name]
      })
    }
  }

  function handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = ev.target
    formik.setFieldValue(name as any, value)
  }

  function handleStyleChange(name: string) {
    return function (value: any) {
      formik.setFieldValue(name as any, value)
    }
  }
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(CoverForm)
