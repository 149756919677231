import { Box, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { getDetails, getFeature, getObjectLabels, getQuoteOptions, setQuoteOptions } from '@paintscout/util/builder'
import { isEmpty } from '@paintscout/util/util'
import {
  FormSection,
  FormSectionTitle,
  HtmlContent,
  Link,
  Typography,
  getPresentationContentStyles,
  useClientOptions,
  useUser
} from '@ui/paintscout'
import classnames from 'classnames'
import type { QuoteDocument } from 'paintscout'
import React, { useMemo, useState } from 'react'
import type { Item } from '../../../ItemTable'
import TotalTable from '../../../TotalTable'
import { WisetackApplication, WisetackPromo } from '../../../Wisetack'
import { useQuote } from '../../../context'
import BulkActions from '../../BulkActions'
import FilesByItem from '../../FilesByItem'
import InvoiceAlert from '../../InvoiceAlert'
import QuoteHeader from '../../QuoteHeader'
import QuoteTerms from '../../QuoteTerms'
import AdditionalTable from './AdditionalTable'
import BidTable from './BidTable'
import OptionsAddedTable from './OptionsAddedTable'
import OptionsTable from './OptionsTable'
import PaymentsTable from './PaymentsTable'
import PendingTable from './PendingTable'

const useStyles = makeStyles<Theme, QuoteViewProps>(
  (theme) => ({
    root: {
      marginBottom: 20,
      ...getPresentationContentStyles(theme, { noMargins: ['li'] })
    },

    headerWrapper: {},
    spacer: {},
    row: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between'
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },
    editButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    editButton: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    },

    termsSection: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate',
      '@media print': {
        pageBreakBefore: 'always'
      }
    },
    picturesSection: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    noteSection: {
      background: theme.palette.grey['100'],
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
      // whiteSpace: 'pre-wrap'
    },
    optionsTable: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    paymentsTable: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginCollapse: 'separate'
    },
    applicationWrapper: {
      marginTop: theme.spacing(2)
    }
  }),
  { name: 'QuoteView' }
)

export interface QuoteViewProps {
  inPresentation: boolean
  online: boolean
}

const BASE_SELECTED = {
  bid: [],
  options: [],
  additional: [],
  pending: [],
  archived: [],
  'added-options': []
}

interface SelectedItems {
  bid: RenderableItem[]
  options: RenderableItem[]
  additional: RenderableItem[]
  pending: RenderableItem[]
  archived: RenderableItem[]
  'added-options': RenderableItem[]
}

function QuoteView(props: QuoteViewProps) {
  const classes = useStyles(props)
  const { inPresentation, online } = props
  const [selectedItems, setSelectedItems] = useState<SelectedItems>(BASE_SELECTED)
  const { options } = useClientOptions()
  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })
  const { tableView, quote, isEditable, canAddAdditionalWork, isCustomerView, onEditNote, onItemAction, updateQuote } =
    useQuote()
  const { preferences, isTrial } = useUser()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const wisetackSignup = options.options.wisetackSignup
  const showWisetackApplication =
    getFeature({ options, preferences, path: 'integrations.providers.wisetack' }) &&
    online &&
    !quote.contact?.company &&
    wisetackSignup?.status !== 'APPLICATION_DECLINED'

  const hasOptionsApproval = options?.options?.optionsApproval?.enabled
  const details = useMemo(() => getDetails({ quote, options }), [quote, options])
  const hasClientNote = !isEmpty(details.notes.client)
  const { showQuoteFiles, showQuoteOptions, showQuotePayments } = getQuoteOptions({
    quote,
    options
  })
  const allowQuotePayments = options.options.quotes?.allowQuotePayments

  const showPaymentsTable =
    !!quote.totals.after_tax && (quote.is_invoice || allowQuotePayments || (quote.payments ?? []).length > 0)
  const showPaymentsTablePresentation =
    !!quote.totals.after_tax && (quote.payments ?? []).filter((payment) => payment.status !== 'pending').length > 0
  const showOptions = true
  const hasAdditionalWork = quote.is_invoice || allowQuoteAdditionalWork

  const handleBulkActionClick = (action: string, previousSection: QuoteItemSection) => {
    return (selectedItems: RenderableItem[]) => {
      onItemAction(
        action,
        previousSection,
        selectedItems.map((item) => item.key)
      )
      resetSelectedItems()
    }
  }

  const handleItemSelection = ({
    section,
    selectedItems: selectedItemsOuter
  }: {
    section: QuoteItemSection
    selectedItems: Item[]
  }) => {
    setSelectedItems((selectedItems) => {
      return {
        ...selectedItems,
        [section]: selectedItemsOuter
      }
    })
  }

  return (
    <>
      <div className={classes.root} id={'quote'} data-testid="quote">
        <div className={classes.headerWrapper}>
          <InvoiceAlert inPresentation={inPresentation} />
          <QuoteHeader />
        </div>

        <div>
          <BidTable
            selectedItems={getSelectedItems({ section: 'bid' })}
            onItemSelection={(_event, selectedItems) => handleItemSelection({ section: 'bid', selectedItems })}
            onDeselectItem={handleDeselectItem}
            onItemAction={(action, section, item) => handleItemAction(action, section, item, onItemAction)}
          />
          {hasOptionsApproval && (
            <OptionsAddedTable
              selectedItems={getSelectedItems({ section: 'added-options' })}
              onItemAction={(action, section, item) => handleItemAction(action, section, item, onItemAction)}
              onItemSelection={(_event, selectedItems) =>
                handleItemSelection({ section: 'added-options', selectedItems })
              }
            />
          )}
          {hasAdditionalWork && (
            <AdditionalTable
              selectedItems={getSelectedItems({ section: 'additional' })}
              onItemSelection={(_event, selectedItems) => handleItemSelection({ section: 'additional', selectedItems })}
              onItemAction={(action, section, item) => handleItemAction(action, section, item, onItemAction)}
            />
          )}
          <PendingTable
            selectedItems={getSelectedItems({ section: 'pending' })}
            onItemSelection={(_event, selectedItems) => handleItemSelection({ section: 'pending', selectedItems })}
            onItemAction={(action, section, item) => handleItemAction(action, section, item, onItemAction)}
          />

          {details.totals.show && (
            <TotalTable>
              {wisetackSignup?.status !== 'APPLICATION_DECLINED' && <WisetackPromo inPresentation={inPresentation} />}
            </TotalTable>
          )}
        </div>

        {showWisetackApplication && (
          <div className={classes.applicationWrapper}>
            <WisetackApplication inPresentation={inPresentation} inCustomerView={isCustomerView} />
          </div>
        )}

        {hasClientNote && (
          <div className={classnames(classes.spacer, classes.noteSection)} onClick={isEditable ? onEditNote : null}>
            <HtmlContent content={details.notes.client} />
          </div>
        )}

        {(inPresentation ? showPaymentsTablePresentation : showPaymentsTable) && (
          <div
            className={classnames({
              [classes.spacer]: true,
              [classes.paymentsTable]: true,
              [classes.hidePrint]: !showQuotePayments
            })}
          >
            <PaymentsTable
              inPresentation={inPresentation}
              onChange={handlePaymentsChange}
              collapsed={!showQuotePayments}
              onToggle={(ev: any) => handleToggle(ev, 'showQuotePayments')}
            />
          </div>
        )}
        {showOptions && (
          <div
            className={classnames({
              [classes.spacer]: true,
              [classes.optionsTable]: true
            })}
          >
            <OptionsTable
              showAddButton={isEditable}
              selectedItems={getSelectedItems({ section: 'options' })}
              onItemSelection={(_event, selectedItems) => handleItemSelection({ section: 'options', selectedItems })}
              onItemAction={(action, section, item) => handleItemAction(action, section, item, onItemAction)}
              collapsed={!showQuoteOptions}
              onToggle={(ev: any) => handleToggle(ev, 'showQuoteOptions')}
              inPresentation={inPresentation}
            />
          </div>
        )}

        {!inPresentation && (
          <div className={classnames({ [classes.hidePrint]: !showQuoteFiles })}>
            <FilesByItem
              FormSectionTitle={
                <FormSectionTitle
                  title={'Media'}
                  subTitle={
                    isTrial && (
                      <Box mb={1}>
                        <Typography variant="subtitle1">
                          Trial plans are limited to 3 media files per {objectLabels.quote.value}.{' '}
                          <Link to="/settings?section=billing-plan" underline="always">
                            Upgrade Now!
                          </Link>
                        </Typography>
                      </Box>
                    )
                  }
                  showToggle={isEditable}
                  toggleValue={showQuoteFiles}
                  style={{ marginBottom: 0 }}
                  onToggle={(ev: any) => handleToggle(ev, 'showQuoteFiles')}
                />
              }
              sections={['bid', 'additional', 'added-options', 'pending', 'options']}
              consumer={'customer'}
            />
          </div>
        )}

        {!inPresentation && !isEditable && (
          <FormSection hideDivider={true}>
            <QuoteTerms isQuoteView={true} />
          </FormSection>
        )}
      </div>
      {(isEditable || canAddAdditionalWork) && (
        <BulkActions
          options={options}
          quote={quote}
          tableView={tableView}
          getSelectedItems={getSelectedItems}
          getSelectedItemsByType={getSelectedItemsByType}
          onBulkActionClick={(action, previousSection) =>
            handleBulkActionClick(action, previousSection as QuoteItemSection)
          }
          onDeselectAll={handleDeselectAll}
        />
      )}
    </>
  )

  function getSelectedItems(args?: { section?: QuoteItemSection }): RenderableItem[] {
    const { section } = args ?? {}
    const { bid, options, additional, pending, archived } = selectedItems
    if (section) {
      return selectedItems[section]
    }

    return [...bid, ...options, ...additional, ...pending, ...archived, ...selectedItems['added-options']]
  }

  function getSelectedItemsByType(type: string): RenderableItem[] {
    const { bid, options, additional, pending, archived } = selectedItems
    const items = [...bid, ...options, ...additional, ...pending, ...archived]

    return items.filter((item) => item.type === type)
  }

  function handleItemAction(action: string, section: QuoteItemSection, item: RenderableItem, onItemAction: any) {
    const moveActions = ['make-option', 'archive', 'unarchive', 'move-to-bid', 'move-to-additional', 'move-to-pending']
    if (moveActions.includes(action)) {
      handleDeselectItem({ section, key: item.key })
    }
    onItemAction?.(action, section, [item.key])
  }

  function handleDeselectItem({ section, key }: { section: QuoteItemSection | 'all'; key?: string }) {
    if (section === 'all') {
      handleDeselectAll()
    } else {
      setSelectedItems((selectedItems) => {
        return {
          ...selectedItems,
          [section]: selectedItems[section].filter((items) => items.key !== key)
        }
      })
    }
  }

  function handleDeselectAll() {
    resetSelectedItems()
  }

  function handlePaymentsChange(quote: QuoteDocument) {
    updateQuote({ quote })
  }

  function handleToggle(event: any, name: string) {
    const quoteOptions = getQuoteOptions({ options, quote })

    quoteOptions[name] = !quoteOptions[name]

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  function resetSelectedItems() {
    setSelectedItems(BASE_SELECTED)
  }
}

export default QuoteView
