/**
 * Get area totals
 * @param  {GenerateSubstrateDescription}    args
 * @return {string}
 */

import type { QuoteDocument, OrderItem, QuoteOptions, OptionsDocument, RateOption } from 'paintscout'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

import type { QuoteItemSection } from '../../index'
import { getItemSection } from '../get-item-section/get-item-section'
import { filterItem } from '../filter-item'
import { getAreaSubstrate } from '../../area-substrates/get-area-substrate'
import { makeHtml } from '../../../../util'
import mergeLabels from '../merge-labels'
import { isInSection } from '../../area-substrates/is-in-section'
import { filterGroupedSubstrate } from './filter-grouped-substrate'

export function generateSubstrateDescription(args: {
  quote: QuoteDocument
  substrateKey: string
  options?: QuoteOptions
  clientOptions: OptionsDocument
  section?: QuoteItemSection
  noReportText?: boolean
  showGroup?: boolean
  groupKey?: string
}): string {
  const { quote, substrateKey, clientOptions, options, noReportText, showGroup, groupKey } = args
  const showQuantities = get(options, 'showQuantities', false)

  const section = args.section ? args.section : 'bid'

  const areaLabels: string[] = []
  const reportText: string[] = []
  const quantities: any = {}
  const prices: any = {}
  const prep: any = {}
  const coats: any = {}
  const products: any = {}

  quote.order.area.forEach((orderItem: OrderItem) => {
    if (orderItem.type !== 'area') {
      return
    }
    if (orderItem.parent) {
      return
    }
    if (orderItem._deleted) {
      return
    }

    const areaKey = orderItem.key
    const area = quote.areas[areaKey]
    if (!area) {
      return
    }

    if (area.substrateOrder) {
      area.substrateOrder.forEach((substrateOrderItem: OrderItem) => {
        const areaSubstrate = getAreaSubstrate({
          quote,
          options: clientOptions,
          areaKey,
          key: substrateOrderItem.key,
          full: true
        })

        if (!areaSubstrate) {
          return
        }

        const rate: RateOption = areaSubstrate.rate

        let rateKey
        if (quote.substratesGroupBy === 'client_label') {
          rateKey = get(areaSubstrate, 'rate.client_label')
          if (!rateKey) {
            rateKey = get(areaSubstrate, 'rate.label', 'Custom')
          }
          if (!rateKey) {
            rateKey = 'Custom'
          }
          rateKey = rateKey.trim().toLowerCase()
        } else {
          rateKey = areaSubstrate.rate.name === 'custom' ? areaSubstrate.key : areaSubstrate.rate.name
        }

        if (substrateKey.trim().toLowerCase() !== rateKey) {
          return
        }
        const areaSubstrateSection = getItemSection(areaSubstrate)
        if (
          !isInSection({
            areaSubstrateSection,
            areaSection: getItemSection(area),
            section
          })
        ) {
          return null
        }

        const groupFilter = filterGroupedSubstrate({
          quote,
          areaSubstrate,
          section,
          showGroup,
          groupKey
        })
        if (groupFilter) {
          return
        }

        // Dont include in description if areaSubstrate is not in same section
        if (areaSubstrateSection !== section) {
          return
        }

        // Dont include archived areaSubstrates if not the section we are looking for
        if (section !== 'archived' && areaSubstrateSection === 'archived') {
          return
        }

        // Add areaSubstrate to label and reportText
        const label = area.area_label.value
        areaLabels.push(label)
        reportText.push(rate.report_text)

        // Handle materials on areaSubstrate
        let materials = 0
        const allProducts = [
          ...(Object.keys(areaSubstrate?.product ?? {}).length ? [areaSubstrate.product] : []),
          ...(areaSubstrate?.products ?? [])
        ]
        for (const product of allProducts) {
          if (product?.totalPrice_override && typeof product?.totalPrice_override_value === 'number') {
            materials = product.totalPrice_override_value
          } else {
            materials = Number(product?.totalPrice ?? 0)
          }
        }

        // Handle products on areaSubstrate
        if (!products[label]) {
          products[label] = []
        }

        for (const areaSubstrateProduct of allProducts) {
          const productString = [areaSubstrateProduct?.product?.label ?? '', areaSubstrateProduct?.product?.color ?? '']
            .filter((item) => item)
            .join(' - ')
          if (productString && areaSubstrate.showProduct && !products?.[label]?.includes(productString)) {
            products[label].push(productString)
          }
        }

        // Handle price on areaSubstrate
        let price = 0
        if (areaSubstrate.override_price && typeof areaSubstrate.override_price_value === 'number') {
          price = areaSubstrate.override_price_value
        } else if (areaSubstrate.override_price) {
          price = areaSubstrate.price ?? 0
        } else {
          price = areaSubstrate.default_price ?? 0
        }

        if (!prices[label]) {
          prices[label] = 0
        }
        if (areaSubstrate.showPrice) {
          prices[label] += price + materials
        }

        // Handle coats on areaSubstrate
        if (areaSubstrate.showCoats && areaSubstrate.rate?.useCoats) {
          coats[label] = `${areaSubstrate.coats} Coat${areaSubstrate.coats === 1 ? '' : 's'}`
        }

        // Handle prep on areaSubstrate
        if (areaSubstrate.showPrep && areaSubstrate.prep) {
          prep[label] = `${areaSubstrate.prep} hr${areaSubstrate.prep === 1 ? '' : 's'} prep`
        }

        // Handle Quantities on areaSubstrate
        if (showQuantities && !areaSubstrate.hideQuantity && rate.rate_type === 'quantity') {
          if (!quantities[label]) {
            quantities[label] = 0
          }

          quantities[label] += areaSubstrate.quantity
        }
      })
    }

    // If nothing added and not in section, skip area
    if (!filterItem(area, section) && !areaLabels.length) {
      return
    }
  })

  // Combine labels to create description
  const descriptionElements = mergeLabels(clientOptions, areaLabels, quantities, prices, coats, prep, products)

  // Combine all descriptions and reports
  const joinedDescriptionElements = descriptionElements.join(', ')
  const htmlDescription = makeHtml(joinedDescriptionElements)

  const reportTextItems = noReportText
    ? []
    : uniq(reportText.filter((item) => item)).map((item) => {
        return makeHtml(item, { replaceBreaks: true })
      })

  const ret = [htmlDescription, reportTextItems.join('')].filter((item) => item).join('')
  return ret
}
