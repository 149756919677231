import type { OptionsDocument, QuoteOptions } from 'paintscout'
import type { UpdateableProduct } from '../../../area-substrates'
import getProductFields from './get-product-fields'
import type { ProductFields } from './get-product-fields'

/**
 * @description Crew description and materialValues to be displayed
 * @returns crewDescription and productFields
 */
export const getCrewDescription = ({
  products,
  prep,
  hours,
  itemName,
  clientLabel,
  showValues,
  quoteOptions,
  substrate,
  options
}: {
  products?: UpdateableProduct[]
  quoteOptions: QuoteOptions
  prep: number
  hours: number
  itemName: string
  clientLabel: string
  showValues?: boolean
  substrate?: boolean
  options: OptionsDocument
}): { crewDescription: string; productFields: ProductFields[] } => {
  const descriptionComponents = []
  /* "secondLine" is the line that has the client label and/or the prep/hours */
  const secondLine = []
  const hoursComponents = []

  if (substrate) {
    // toRenderableCrewSubstrateArea filter
    if (clientLabel || (clientLabel == '' && clientLabel.trim().toLowerCase() !== itemName.trim().toLowerCase())) {
      secondLine.push(itemName)
    }
  } else {
    // toRenderableCrewAreaSubstrate filter
    if (clientLabel && clientLabel !== itemName) {
      secondLine.push(itemName)
    }
  }
  if (prep && showValues) {
    hoursComponents.push(`Prep: ${Math.round(prep * 100) / 100}`)
    hoursComponents.push(`Labor: ${Math.round(hours * 100) / 100}`)
  }

  const labourHours = hoursComponents.join(' + ')
  if (labourHours) {
    secondLine.push(labourHours)
  }

  if (secondLine.filter(Boolean).length) {
    descriptionComponents.push(secondLine.join(' - '))
  }

  // Generate product related fields and description string
  // Combine dupe products for description
  const productFields: ProductFields[] = []
  const combinedProductFields = {}
  for (const product of products ?? []) {
    combinedProductFields[product.key] = combinedProductFields[product.key]
      ? {
          ...combinedProductFields[product.key],
          quantity: combinedProductFields[product.key]?.quantity ?? 0 + product.quantity,
          totalPrice: {
            useCustom: false,
            custom: 0,
            default:
              (combinedProductFields[product.key]?.totalPrice?.useCustom
                ? combinedProductFields[product.key]?.totalPrice?.custom
                : combinedProductFields[product.key]?.totalPrice?.default ?? 0) +
              (product?.totalPrice?.useCustom ? product.totalPrice?.custom ?? 0 : product?.totalPrice?.default ?? 0)
          },
          volume: {
            useCustom: false,
            custom: 0,
            default:
              (combinedProductFields[product.key]?.volume?.useCustom
                ? combinedProductFields[product.key]?.volume?.custom
                : combinedProductFields[product.key]?.volume?.default ?? 0) +
              (product?.volume?.useCustom ? product.volume?.custom ?? 0 : product?.volume?.default ?? 0)
          }
        }
      : product

    const { productFields: generatedFields } = getProductFields({
      product,
      quoteOptions,
      clientOptions: options
    })
    productFields.push(generatedFields)
  }

  for (const productKey of Object.keys(combinedProductFields) ?? []) {
    const product = combinedProductFields[productKey]
    const { descriptionString } = getProductFields({
      product,
      quoteOptions,
      clientOptions: options
    })

    descriptionComponents.push(descriptionString)
  }

  // Clean and join our description components
  // the if statement above is sometimes pushing an empty string into descriptionComponents, the following removes them
  const noEmptyDescriptionComponents = descriptionComponents.filter((elem) => elem.length > 0)
  const crewDescription: string = noEmptyDescriptionComponents.join('\n')

  return { crewDescription, productFields }
}
