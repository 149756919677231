/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection, RenderableItem } from '../../index'
import { getItems } from '../../items/get-items'

import { moveItem } from './move-item'

import { calculateQuote } from '../../util'

export function moveItems(args: {
  quote: QuoteDocument
  options: OptionsDocument
  keys: string[]
  areaIds?: { [key: string]: string[] } // For substrate moves, substrate keys related to the areaIds they are in { [substrateKey]: [areaId] }
  section: QuoteItemSection
  previousSection?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, keys, section, previousSection } = args
  let { areaIds = {} } = args
  const movingSubstrate = keys.filter((key) => quote.substrates[key])
  if (movingSubstrate.length && !Object.keys(areaIds).length) {
    // If not given and we are moving substrates then we will need to find the areaIds
    areaIds = getItems({
      quote,
      section: previousSection,
      view: 'substrate',
      consumer: 'customer',
      keys: movingSubstrate
    }).reduce((acc: { [key: string]: string[] }, item: RenderableItem) => {
      return {
        ...acc,
        [item.key]: [...(acc[item.key] ?? []), ...((item?.additionalFields?.areaIds as string[]) ?? [])].filter(Boolean)
      }
    }, {})
  }

  const finalQuote = keys.reduce(
    (updatedQuote: QuoteDocument, key: string) => {
      return moveItem({ quote: updatedQuote, options, key, section, previousSection, calculate: false, areaIds })
    },
    { ...quote }
  )

  if (section === 'additional') {
    // check if there are remaining additional items
    // if not, we'll blast the "approval" information
  }

  return calculateQuote({ quote: finalQuote, options })
}
