import type { AreaSubstrate, QuoteDocument, QuoteOptions, QuoteArea, OptionsDocument, OrderItem } from 'paintscout'
import type { RenderableSubItem } from '../..'
import { formatAreaSubstrate } from './format-area-substrate'
import type { QuoteItemSection, QuoteItemConsumer, QuoteItemView } from '../../..'
import { getQuoteOptions } from '../../../util'
import { getAreaSubstrate } from '../../../area-substrates'
import { toRenderableItemSubstrate } from '../../../area-substrates/util'
import type { UpdateableArea } from '../../../areas'
import { toRenderableCrewAreaSubstrate } from '../get-crew-items-by-area/util'
import toRenderableCrewSubstrateArea from '../get-crew-items-by-substrate/util/to-renderable-crew-substrate-area'

// Correctly format a substrate area based on consumer and view
export const formatSubstrateArea = ({
  areaSubstrate,
  quote,
  areaItem,
  quoteOptions,
  area,
  clientOptions,
  areaSubstrateOrderItem,
  key,
  section,
  showValues,
  consumer,
  view
}: {
  areaSubstrate: AreaSubstrate
  quote: QuoteDocument
  areaItem: UpdateableArea
  quoteOptions: QuoteOptions
  area: QuoteArea
  clientOptions: OptionsDocument
  areaSubstrateOrderItem: OrderItem
  key: string
  section: QuoteItemSection
  showValues: boolean
  consumer?: QuoteItemConsumer
  view?: QuoteItemView
}): RenderableSubItem => {
  const renderableItemSubstrate = toRenderableItemSubstrate({
    areaSubstrate,
    options: getQuoteOptions({ quote }),
    quote,
    area: areaItem
  })

  if (consumer === 'crew') {
    if (view == 'substrate') {
      const updateableAreaSubstrate = getAreaSubstrate({
        quote,
        options: clientOptions,
        areaKey: key,
        key: areaSubstrateOrderItem.key,
        full: false
      })

      if (!updateableAreaSubstrate) {
        return null
      }

      return toRenderableCrewSubstrateArea({
        key: areaSubstrate.key,
        quote,
        area,
        item: updateableAreaSubstrate,
        showValues,
        quoteOptions,
        options: clientOptions
      })
    } else {
      return toRenderableCrewAreaSubstrate({
        key: areaSubstrate.key,
        quote,
        areaSubstrate: renderableItemSubstrate,
        area,
        showValues,
        options: clientOptions
      })
    }
  } else {
    if (renderableItemSubstrate.inAreaGroup || renderableItemSubstrate.workOrderOnly) {
      return null
    }
    const res = formatAreaSubstrate({
      clientOptions,
      quote,
      key: areaSubstrate.key,
      areaKey: area.key,
      item: renderableItemSubstrate,
      options: quoteOptions,
      section
    })

    return {
      ...res,
      additionalFields: {
        ...res.additionalFields,
        products: areaSubstrate.product
      }
    }
  }
}
