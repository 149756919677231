import type { ThemeOptions } from '@material-ui/core'
import { createTheme as createBaseTheme } from '@ui/core/theme'
import merge from 'lodash/merge'
import responsiveFontSizes from './responsiveFontSizes'

export function createTheme(options?: ThemeOptions) {
  const { fontScale = 1 } = options?.paintscout ?? {}

  const fontSizes = {
    h1: 2.0,
    h2: 1.8,
    h3: 1.4,
    h4: 1.1,
    h5: 1.0,
    h6: 0.8,
    subtitle1: 1.0,
    subtitle2: 0.8,
    body1: 1.0,
    body2: 0.8,
    button: 1.0,
    caption: 0.8,
    overline: 0.75
  }

  Object.keys(fontSizes).forEach((size: string) => {
    fontSizes[size] = `${fontSizes[size] * fontScale}rem`
  })

  const lineHeight = 1.4
  const letterSpacing = '0.137em'
  const fontWeight = 400
  const fontWeightMedium = 500
  const fontWeightBold = 700

  const black = '#3E4449'
  const grey = '#AAAAAA'
  const white = '#fff'

  const statusColors = {
    draft: '#AAAAAA',
    sent: '#F5A623',
    onHold: '#F5A623',
    viewed: '#50A0CF',
    accepted: '#60B948',
    disputed: '#CD2929',
    declined: '#CD2929',
    invoiced: '#33B3A6',
    partial: '#8A63A4',
    paid: '#60B948',
    overdue: '#CD2929'
  }

  const severityColors = {
    warning: {
      main: statusColors.sent,
      light: '#fdebce',
      dark: '#aa6c08',
      contrastText: white
    },
    success: {
      main: statusColors.accepted,
      light: '#dff1da',
      dark: '#2f5c23',
      contrastText: white
    },
    error: {
      main: statusColors.declined,
      light: '#f7d4d4',
      dark: '#801919',
      contrastText: white
    },
    info: {
      main: statusColors.viewed,
      light: '#d7e9f4',
      dark: '#2c76a0',
      contrastText: white
    }
  }

  const theme = createBaseTheme(
    merge(
      {
        overrides: {
          MuiCssBaseline: {
            '@global': {
              button: {
                padding: 0,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                font: 'inherit'
              }
            }
          },
          MuiMenuItem: {
            root: {
              paddingTop: 12,
              paddingLeft: 12,
              paddingRight: 12,
              paddingBottom: 12,
              color: grey[800]
            }
          }
        },
        paintscout: {
          header: {
            height: {
              sm: '50px',
              md: '60px',
              lg: '90px'
            }
          },
          fontScale: 1,
          palette: {
            primary: '#024bb5'
          }
        },
        typography: {
          htmlFontSize: 16,
          fontWeightMedium,
          h1: {
            fontSize: fontSizes.h1,
            color: black,
            lineHeight,
            fontWeight
          },
          h2: {
            fontSize: fontSizes.h2,
            color: black,
            lineHeight,
            fontWeight
          },
          h3: {
            fontSize: fontSizes.h3,
            color: black,
            lineHeight,
            fontWeight
          },
          h4: {
            fontSize: fontSizes.h4,
            color: black,
            lineHeight,
            fontWeight: fontWeightBold
          },
          h5: {
            fontSize: fontSizes.h5,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightBold,
            textTransform: 'uppercase' as const
          },
          h6: {
            fontSize: fontSizes.h6,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightBold,
            textTransform: 'uppercase' as const
          },
          subtitle1: {
            fontSize: fontSizes.subtitle1,
            color: grey,
            lineHeight,
            fontWeight
          },
          subtitle2: {
            fontSize: fontSizes.subtitle2,
            color: grey,
            lineHeight,
            fontWeight
          },
          body1: {
            fontSize: fontSizes.body1,
            lineHeight,
            fontWeight
          },
          body2: {
            fontSize: fontSizes.body2,
            lineHeight,
            fontWeight
          },
          button: {
            fontSize: fontSizes.button,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightBold,
            textTransform: 'uppercase' as const
          },
          caption: {
            fontSize: fontSizes.caption,
            lineHeight,
            fontWeight
          },
          overline: {
            fontSize: fontSizes.overline,
            lineHeight,
            letterSpacing,
            fontWeight: fontWeightBold,
            textTransform: 'uppercase' as const
          }
        },
        palette: {
          quote: {
            status: statusColors
          },
          ...severityColors,
          activityType: {
            created: statusColors.draft,
            edited: statusColors.draft,
            'conflict-resolution': statusColors.draft,
            'status-changed': statusColors.draft,
            'status-updated': statusColors.draft,
            archived: statusColors.draft,
            unarchived: statusColors.draft,
            copied: statusColors.draft,
            deleted: statusColors.draft,
            ...statusColors,
            payment: '#8A63A4',
            assignment: statusColors.sent,
            error: statusColors.declined,
            warning: statusColors.sent,
            downloaded: statusColors.draft,
            merged: statusColors.draft,
            'owner-changed': statusColors.draft,
            'type-changed': statusColors.draft,
            message: '#50A0CF'
          },
          text: {
            primary: '#3E4449'
          },
          grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#3E4449', // custom
            A700: '#616161'
          }
        }
      },
      options
    )
  )
  const withFontSizes = fontScale < 1 ? theme : responsiveFontSizes(theme)

  return withFontSizes
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    paintscout: {
      header: {
        height: {
          sm: string
          md: string
          lg?: string
        }
      }
      fontScale: number
      palette: {
        primary: string
      }
    }
  }

  interface ThemeOptions {
    paintscout?: {
      header?: {
        height?: {
          sm?: string
          md?: string
          lg?: string
        }
      }
      fontScale: number
      palette?: {
        primary?: string
      }
    }
  }
}
